import { Component, OnInit, EventEmitter, Input, Output, Renderer2 } from '@angular/core';
import { ThemeService } from '../../services/theme.service';
import { LayoutService } from '../../services/layout.service';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from '../../../helper';
//import { UUID } from 'angular2-uuid';

@Component({
  selector: 'app-header-side',
  templateUrl: './header-side.template.html'
})
export class HeaderSideComponent implements OnInit {
  @Input() notificPanel;
  public availableLangs = [{
    name: 'EN',
    code: 'en'
  }, {
    name: 'FR',
    code: 'fr'
  }]
  currentLang = this.availableLangs[0];

  public egretThemes;
  public layoutConf:any;
  constructor(public helper: Helper,
    private themeService: ThemeService,
    private layout: LayoutService,
    public translate: TranslateService,
    private renderer: Renderer2
  ) {}
  ngOnInit() {
    this.egretThemes = this.themeService.egretThemes;
    this.layoutConf = this.layout.layoutConf;

    let device_id = localStorage.getItem('device_id');
    if (device_id == "" || device_id == null || device_id == undefined) {
      /* let uuid = UUID.UUID();
       localStorage.setItem('device_id', uuid);
    */
    }
    this.helper.http_post_method_requester(this.helper.POST_METHOD.USER_SETTING_DETAIL, { device_id: localStorage.getItem('device_id') }, true, true, true, true, (response_data) => {
      this.availableLangs = response_data.setting_detail.lang;
      var language = localStorage.getItem('company_language');
      if (language == '' || language == undefined) {
        language = 'en'
      }
      localStorage.setItem('company_language', language);
      this.helper.trans.use(language);
      var index = this.availableLangs.findIndex(i => i.code == language);
      this.currentLang = this.availableLangs[index];
    })
  }
  setLang(lng) {
    localStorage.setItem('company_language',lng.code);
    this.currentLang = lng;
    this.helper.trans.use(this.currentLang.code);
    location.reload()
  }
  logout(){
    localStorage.removeItem('company_detail');
        this.helper.router.navigate(['company', 'login'])
  }
  changeTheme(theme) {
    // this.themeService.changeTheme(theme);
  }
  toggleNotific() {
    this.notificPanel.toggle();
  }
  toggleSidenav() {
    if(this.layoutConf.sidebarStyle === 'closed') {
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full'
      })
    }
    this.layout.publishLayoutChange({
      sidebarStyle: 'closed'
    })
  }

  toggleCollapse() {
    // compact --> full
    if(this.layoutConf.sidebarStyle === 'compact') {
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full',
        sidebarCompactToggle: false
      }, {transitionClass: true})
    }

    // * --> compact
    this.layout.publishLayoutChange({
      sidebarStyle: 'compact',
      sidebarCompactToggle: true
    }, {transitionClass: true})

  }

  onSearch(e) {
    //   console.log(e)
  }
}