export var POST_METHOD = {

	GET_COUNTRY_LIST: '/api/get_country_list',
	CANCEL_REQUEST: '/api/company/CANCEL_REQUEST',
	COMPANY_SIGNUP: '/api/company/signup',
	COMPANY_SIGNIN: '/api/company/login',
	COMPANY_UPDATE: '/api/company/update',
	COMPANY_SETTLEMENT: '/api/company_settlement', 
	COMPANY_ASSIGN_PROVIDER: '/api/company/assign_provider',
	COMPANY_UPDATE_SERVICE_PRICE: '/api/company/update_service_price',
	COMPANY_DETAIL: '/api/company_list',
	GET_REQUESTS_LIST: '/api/get_request_list',
	GET_HISTORY: '/api/get_history',
	GET_CITY_SERVICE_LIST: '/api/admin/get_city_service_list',
	GET_EARNING: '/api/get_earning',
	GET_PROVIDER_LIST: '/api/get_provider_list',
	COMPANY_PROVIDER_LIST: '/api/company_provider_list',
	GET_SERVICE_LIST: '/api/admin/get_service_list',
	USER_FORGOT_PASSWORD:'/api/forgot_password',
	USER_SETTING_DETAIL:'/api/company/get_setting_detail',
	USER_RESET_PASSWORD:'/api/reset_password',
	GET_CITY_LIST: '/api/get_city_list',
	ADD_PROVIDER: '/api/provider/register',
	UPDATE_PROVIDER_DETAIL: '/api/update_provider_detail',
	CITY_SERVICE_LIST: '/api/get_provider_service_sub_service_list',
	GET_SERVICE_PRICE_LIST: '/api/get_service_price_list',
	GET_DOCUMENT_LIST: '/api/get_document_list',
	UPLOAD_DOCUMENT: '/api/upload_document',
	PROVIDER_APPROVE_DECLINE: '/api/provider_approve_decline',
	UPDATE_REQUEST: '/api/update_request',
	GET_WALLET_HISTORY_LIST: '/api/admin/get_wallet_history_list',
	GET_PROVIDER_NOT_AVAILABILITY_LIST: '/api/get_not_availability',


	/*GET_COUNTRY_CITY_LIST: '/api/get_country_city_list',
	GET_ALL_COUNTRY_LIST: '/api/get_all_country_list',
	GET_COUNTRY_DATA: '/api/get_country_data',
	UPDATE_CITY_DETAIL: '/api/update_city_detail',
	ADD_CITY_DETAIL: '/api/add_city_detail',
	UPDATE_COUNTRY_DETAIL: '/api/update_country_detail',
	ADD_COUNTRY_DETAIL: '/api/add_country_detail',
	UPDATE_SERVICE_DETAIL: '/api/update_service_detail',
	ADD_SERVICE_DETAIL: '/api/add_service_detail',
	UPDATE_SUB_SERVICE_DETAIL: '/api/update_sub_service_detail',
	ADD_SUB_SERVICE_DETAIL: '/api/add_sub_service_detail',
	UPDATE_SERVICE_PRICE_DETAIL: '/api/update_service_price_detail',
	ADD_SERVICE_PRICE_DETAIL: '/api/add_service_price_detail',
	UPDATE_SUB_SERVICE_SEQUENCE_NUMBER: '/api/update_sub_service_sequence_number',
	GET_USER_LIST: '/api/get_user_list',
	USER_APPROVE_DECLINE: '/api/user_approve_decline',
	UPDATE_USER_DETAIL: '/api/update_user_detail',
	GET_SETTING_DETAIL: '/api/admin/get_setting',
	UPDATE_SETTING_DETAIL: '/api/admin/update_setting',
	CHECK_CITY_EXIST: '/api/check_city_exist',

	PROVIDER_SETTLEMENT: '/api/provider_settlement',
	GET_DASHBOARD_TOTAL: '/api/get_dashboard_total',
	GET_REQUEST_CHART_DATA: '/api/get_request_chart_data',
	GET_USER_PROVIDER_CHART_DATA: '/api/get_user_provider_chart_data',
	GET_USER_REQUEST_ANALYTICS_DATA: '/api/get_user_request_analytics_data',
	GET_TOP_PROVIDER_EARNING_DATA: '/api/get_top_provider_earning_data',

	GET_ADMIN_LIST: '/api/admin/list',
	ADD_ADMIN_DATA: '/api/admin/add',
	UPDATE_ADMIN_DATA: '/api/admin/update',
	DELETE_ADMIN_DATA: '/api/admin/delete',
	ADMIN_LOGIN: '/api/admin/login',

	GET_DOCUMENT_LIST: '/api/admin/document_list',
	UPDATE_DOCUMENT_DATA: '/api/admin/update_document',
	ADD_DOCUMENT_DATA: '/api/admin/add_document',*/
	
}