import { Component, NgZone, OnInit ,ElementRef, ViewChild, Injectable, Inject, Renderer2} from '@angular/core';
import { menu_title , title, status, validation_message, button, heading_title } from './string';
import { POST_METHOD } from './http_methods';
import { REQUEST_STATE , REQUEST_STATUS_ID, PAYMENT_TYPE, SETTLED_BY, CONSTANT, USER_TYPE } from './constant';
import { map } from "rxjs/operators";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Http, Response, RequestOptions, Headers} from '@angular/http';
import { MatDialog } from '@angular/material';
import { Router, ActivatedRoute,  NavigationExtras} from '@angular/router';
//import { AppLoaderService } from './shared/services/app-loader/app-loader.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class Helper {

	loader: boolean = false;
    public title:any = title;
    public status:any = status;
    public menu_title:any = menu_title;
    public validation_message: any = validation_message;
    public button: any = button;
    public heading_title: any = heading_title;
    public POST_METHOD: any = POST_METHOD;
    public PAYMENT_TYPE: any = PAYMENT_TYPE;
    public REQUEST_STATE: any = REQUEST_STATE;
    public REQUEST_STATUS_ID: any = REQUEST_STATUS_ID;
    public CONSTANT: any = CONSTANT;
    public USER_TYPE: any = USER_TYPE;
    public SETTLED_BY: any = SETTLED_BY;
    public dialog_ref: any;
    public price_type_array: any[] = [
        {title: 'fixed', value: 0},
        {title: 'fixed_plus_price', value: 1},
        // {title: 'Hourly Slot', value: 2},
    ]
    public PRICE_TYPE: any = {
        FIXED: 0,
        FIXED_HOURLY: 1,
        HOURLY_SLOT: 2
    }
    public per_page_record: any = [
        10,
        20,
        50,
        100,
    ]

	constructor(/*private loader: AppLoaderService, */public http: Http, public router: Router, public dialog: MatDialog, public toastr: ToastrManager,public trans:TranslateService){

	}

    
    http_post_method_requester(api_name, parameter, loader, stop_loader, success_message, error_message, response) {
     
        if(loader){
			this.loader = loader;
		}
        let header = new Headers();
        // for basic auth

        
        header.append("Authorization", "Basic " + btoa("test:test"));
        header.append("type", '9');

        let company_detail: any = localStorage.getItem('company_detail');
        // console.log(company_detail)
        if(company_detail){
            company_detail = JSON.parse(company_detail);
            header.append("id", company_detail._id.toString());
            header.append("server_token", company_detail.server_token.toString());
        }
        const httpOptions = new RequestOptions({headers: header});

        var base_url = CONSTANT.BASE_URL;
        //var base_url = "";
        api_name = base_url + api_name; 

        
        this.http.post(api_name, parameter, httpOptions).pipe(map((res)=>res.json())).subscribe((res_data) => {
            // console.log(res_data)
            if(stop_loader){
                this.loader = false;
            }
			if (res_data.success) {
				if(success_message == true && res_data.code){
                    if(res_data.code == 102){
                        if(res_data.company_detail.is_approved){
                            this.toastr.successToastr(res_data.message, 'Success!');
                        }
                    }else{
                        this.toastr.successToastr(res_data.message, 'Success!');
                    }
                }    
                response(res_data);
            } else {
				if(error_message == true && res_data.code){
                    this.toastr.errorToastr(res_data.message, 'Oops!'); 
                }
                if(res_data.code == 999 || res_data.code == 998){
                    localStorage.removeItem('company_detail');
                    this.router.navigate(['company', 'login'])
                } else {
                    response(res_data);
                }
            }
        }, (error) => {
            console.log(error)
        });
    }
	phone_number_validation(evt){
        var charCode = (evt.which) ? evt.which : evt.keyCode
        if (charCode > 31 && (charCode < 48 || charCode > 57)){
            return false;
        }
        return true;
    }

    number_validation(evt)
    {
        var charCode = (evt.which) ? evt.which : evt.keyCode
        if (charCode > 31 && (charCode < 48 || charCode > 57))
        {
            if( charCode == 46)
            {
                return true;
            }
            else
            {
                return false;
            }
        }
        return true;
    }

    space_validation(evt){
        if(evt.code == "Space" && evt.target.value.length < 1){
          return false;
        }
        return true
      }
    
}