import { MatListSubheaderCssMatStyler } from "@angular/material"

export var menu_title = {
}

export var status = {
	waiting_for_accept: 'Menunggu Diterima',
	accepted: 'Diterima',
	provider_coming: 'Penyedia Jasa dalam Perjalanan',
	provider_arrived: 'Penyedia Jasa telah Tiba',
	job_started: 'Pesanan Dikerjakan',
	completed: 'Pesanan Selesai',
	no_provider_found: 'Penyedia Jasa tidak ditemukan',
	rejected: 'Ditolak',
	cancelled: 'Dibatalkan',
	cancelled_by_user: 'Dibatalkan oleh Pelanggan',
	cancelled_by_provider: 'Dibatalkan oleh Penyedia Jasa',
	cancelled_by_admin: 'Dibatalkan oleh Admin',
	cancelled_by_company: 'Dibatalkan oleh Perusahaan',

}

export var title = {
	no_data_found:'Data tidak ditemukan',
	select_status: 'Pilih Status',
	all: 'Semua',
	add_provider: 'Tambah Penyedia Jasa',
	company_name: 'Nama Perusahaan',
	otp: 'OTP',
	email: 'Email',
	phone: "Phone",
	username: 'Username',
	password: 'Password',
	new_password: 'Password Baru',
	name: 'Nama',
	select_provider: 'Pilih Penyedia Jasa',
	payment_mode: 'Metode Pembayaran',
	start_date: 'Tanggal Awal',
	end_date: 'Tanggal Akhir',
	city_name: 'Nama Kota',
	latitude: 'latitude',
	longitude: 'longitude',
	city_radius: 'Radius Kota',
	is_use_radius: 'Gunakan Radius',
	is_business: 'Gunakan Bisnis',
	is_promo_apply: 'Gunakan Kode Promo',
	is_zone_business: 'Gunakan Zona Bisnis',
	is_cash_payment_mode: 'Gunakan Pembayaran Tunai',
	is_other_payment_mode: 'Gunakan Metode Pembayaran Lainnya',
	is_wallet_payment: 'Pembayaran Dengan Dompet',
	is_check_provider_wallet_amount_for_received_cash_request: 'Periksa isi dompet Karyawan terkait pembayaran yang masuk dari Metode Bayar Di Tempat',
	provider_min_wallet_amount_for_received_cash_request: 'Batas minimum isi dompet Karyawan dari Metode Bayar di Tempat',
	is_check_provider_wallet_amount_for_received_request: 'Periksa isi dompet Karyawan dari banyaknya Pelanggan yang telah diterima',
	provider_max_cash_on_hand: 'Maksimal Uang Tunai di Karyawan',
	is_provider_earning_add_in_wallet_on_cash_payment: 'Pendapatan Karyawan ditambahkan ke Dompet dari Metode Bayar di Tempat',
	is_provider_earning_add_in_wallet_on_other_payment: 'Pendapatan Karyawan ditambahkan ke Dompet dari Metode Pembayaran Lainnya',
	country_name: 'Nama Negara',
	country_code: 'Kode Negara',
	country_code_2: 'Kode Negara 2',
	currency_code: 'Mata Uang',
	currency_sign: 'Satuan Mata Uang',
	country_phone_code: 'Kode Telepon Negara',
	maximum_phone_number_length: 'Maksimal Panjang Nomor Handphone',
	minimum_phone_number_length: 'Minimum Panjang Nomor Handphone',
	is_distance_unit_mile: 'Jarak Tempuh',
	is_referral_user: 'Referral Pengguna',
	referral_bonus_to_user: 'Bonus Referral untuk Pengguna',
	referral_bonus_to_user_friend: 'Bonus Referral untuk Teman Pengguna',
	is_referral_provider: 'Referral Karyawan',
	no_of_user_use_referral: 'Pengguna Belum Mereferensikan',
	referral_bonus_to_provider: 'Bonus Referral untuk Karyawan',
	referral_bonus_to_provider_friend: 'Bonus Referral untuk Teman Karyawan',
	no_of_provider_use_referral: 'Karyawan Belum Mereferensikan',
	currency_rate: 'Kurs mata uang',
	select_timezone: 'Pilih zona waktu',
	description: 'Deskripsi',
	select_time: 'Pilih waktu',
	select_service: 'Pilih layanan',
	is_include_tax: 'Termasuk pajak',
	tax: 'Pajak',
	admin_profit_type: 'Keuntungan yang didapat sebagai Admin',
	admin_profit_value: 'Nilai Keuntungan Admin',
	price_type: 'Jenis Biaya',
	fixed_price_hour: 'Biaya per Jam',
	fixed_price: 'Biaya Tetap',
	extra_price_for_hour: 'Biaya Tambahan per Jam',
	max_extra_hour: 'Maksimal Tambahan Jam',
	fixed: 'Tetap',
	fixed_hourly: 'Tetap per Jam',
	hourly_slot: 'Slot Kosong',
	select_price_type: 'Pilih jenis biaya',
	start_hour: "Jam Mulai",
	end_hour: "Jam Selesai",
	price: "Biaya",
	is_provider_can_set_price: "Penyedia Jasa dapat mengatur biaya",
	is_user_can_choose_multiple_sub_service: "Pengguna dapat memilih lebih dari satu sub-layanan",
	is_user_can_choose_multiple_service: "Pengguna dapat memilih lebih dari satu layanan",
	wallet: "Dompet",
	enter_wallet_amount: 'Masukkan saldo dompet',
	contact_us_phone: 'Hubungi via telepon',
	contact_us_email: 'Hubungi via email',
	is_show_coming_status: 'Tampilkan status "Dalam Perjalanan"',
	is_show_arrived_status: 'Tampilkan status "Telah Tiba"',
	is_ask_user_to_enter_email: 'Tanya Pengguna untuk memasukkan email',
	is_user_email_verification: 'Gunakan verifikasi Email Pengguna',
	is_user_phone_verification: 'Gunakan verifikasi Nomor Handphone Pengguna',
	is_user_can_use_social: 'Pengguna dapat menggunakan sosial media',
	is_user_can_login_using_verification_code: 'Pengguna dapat masuk dengan kode verifikasi',
	is_user_can_login_using_password: 'Pengguna dapat masuk dengan password',
	otp_length: 'Panjang OTP',
	round_number_up_to: 'Pembulatan angka sampai',
	is_schedule_request: 'Gunakan penjadwalan pesanan',
	schedule_time_gap: 'Jarak waktu penjadwalan',
	schedule_request_create_after_minute: 'Penjadwalan Pesanan sedang dibuat dalam beberapa menit',
	is_provider_email_verification: 'Gunakan verifikasi Email Penyedia Jasa',
	is_provider_phone_verification: 'Gunakan verifikasi Nomor Handphone Penyedia Jasa',
	is_provider_can_use_social: 'Penyedia Jasa dapat menggunakan sosial media',
	is_provider_can_login_using_verification_code: 'Penyedia Jasa dapat masuk dengan kode verifikasi',
	is_provider_can_login_using_password: 'Penyedia Jasa dapat masuk dengan password',
	android_user_app_google_key: 'android_user_app_google_key',
	android_provider_app_google_key: 'android_employee_app_google_key',
	android_store_app_google_key: 'android_store_app_google_key',
	ios_user_app_google_key: 'ios_user_app_google_key',
	ios_provider_app_google_key: 'ios_employee_app_google_key',
	ios_store_app_google_key: 'ios_store_app_google_key',
	access_key_id: 'access_key_id',
	secret_key_id: 'secret_key_id',
	aws_bucket_name: 'aws_bucket_name',
	image_base_url: 'image_base_url',
	is_use_aws_bucket: 'is_use_aws_bucket',
	android_provider_app_gcm_key: 'android_employee_app_gcm_key',
	android_user_app_gcm_key: 'android_user_app_gcm_key',
	stripe_public_key: 'stripe_public_key',
	stripe_secret_key: 'stripe_secret_key',
	android_user_app_version_code: 'android_user_app_version_code',
	is_android_user_app_open_update_dialog: 'is_android_user_app_open_update_dialog',
	is_android_user_app_force_update: 'is_android_user_app_force_update',
	android_provider_app_version_code: 'android_provider_app_version_code',
	is_android_provider_app_open_update_dialog: 'is_android_provider_app_open_update_dialog',
	is_android_provider_app_force_update: 'is_android_provider_app_force_update',
	ios_user_app_version_code: 'ios_user_app_version_code',
	is_ios_user_app_open_update_dialog: 'is_ios_user_app_open_update_dialog',
	is_ios_user_app_force_update: 'is_ios_user_app_force_update',
	ios_provider_app_version_code: 'ios_provider_app_version_code',
	is_ios_provider_app_open_update_dialog: 'is_ios_provider_app_open_update_dialog',
	is_ios_provider_app_force_update: 'is_ios_provider_app_force_update',
	provider_bundle_id: 'provider_bundle_id',
	user_bundle_id: 'user_bundle_id',
	key_id: 'key_id',
	team_id: 'team_id',
	is_production: 'is_production',
	cash: 'cash',
	stripe: 'stripe',
	paypal: 'paypal',
	total_completed: 'Transaksi Selesai',
	total_cancelled: 'Transaksi yang Dibatalkan',
	total: 'Semua Transaksi',
	cash_payment: 'Pembayaran Cash',
	other_payment: 'Pembayaran Lainnya',
	wallet_payment: 'Pembayaran Dengan Dompet',
	provider_profit: 'Keuntungan Penyedia Jasa',
	admin_profit: 'Keuntungan Admin',
	pay_to_provider: 'Bayar ke Penyedia Jasa',
	collect_from_provider: 'Terima dari Penyedia Jasa',
	settled_amount: 'settled_amount',
	remaining_amount: 'Jumlah Tersisa',
	remaining: 'Saldo',
	settlement: 'settlement',
	settled: 'settled',
	card: 'Kartu',
	select_settlement_type: 'Pilih jenis settlement',
	theme_color: 'Warna Tema',
	text_dark_color: 'Warna Tulisan Gelap',
	text_light_color: 'Warna Tulisan Terang',
	text_hint_color: 'text_hint_color',
	button_background_color: 'Warna Tombol',
	button_text_color: 'Warna Tulisan Pada Tombol',
	delete_button_color: 'Hapus Warna Tombol',
	total_request: 'Total Pesanan',
	revenue: 'Pendapatan',
	profit: 'Keuntungan',
	new_user: 'Pengguna Baru',
	new_provider: 'Pegawai Baru',
	users_and_providers: 'Pengguna dan Pegawai',
	requests: 'Pesanan',
	users_request_analytics: 'Analisis Pesanan Pengguna',
	promo_payment: 'Promo Pembayaran',
	provider_detail: 'Rincian Penyedia Jasa',
	user_detail: 'Rincian Pengguna',
	user: 'Pengguna',
	provider: 'Penyedia Jasa',
	document_name: 'Nama Dokumen',
	document_for: 'document_for',
	is_unique_code: 'Gunakan kode unik',
	is_expired_date: 'Telah kadaluwarsa',
	is_mandatory: 'Penugasan lagi',
	yes: "Ya",
	no: "Tidak",
	invalid_email_address: "Masukkan email yang benar",
	invalid_username_length: "Masukkan minimal 6 karakter",
	image_too_large: "Ukuran File Terlalu Besar. Ukuran File Foto <= 900KB",
	search: 'Cari',
	item_per_page: 'Item per halaman',
	payment_type: 'Jenis Pembayaran',
	go_to_page: 'Pergi ke halaman',
	company: 'Perusahaan',
	is_provider_can_register_by_app: 'Dapatkah Penyedia Jasa Mendaftar Via Aplikasi?',
	select_country: 'Pilih Negara',
	user_string_file: 'user_string_file',
	admin_string_file: 'admin_string_file',
	select_city: 'Pilih kota',
	referral_code: 'Kode Referral',
	card_payment: 'Pembayaran dengan Kartu',
	date_filter: 'Filter Tanggal',
	today: 'Hari ini',
	yesterday: 'Kemarin',
	last_7_days: '7 hari lalu',
	last_28_days: '28 hari lalu',
	last_30_days: '30 hari lalu',
	custom: 'custom',
	completed: 'Selesai',
	cancelled: 'Dibatalkan',
	total_user: 'Total Pengguna',
	total_provider: 'Total Penyedia Jasa',
	first_time_user: 'Pengguna Baru',
	approved: 'Telah disetujui',
	approve: 'Disetujui',
	declined: 'Telah ditolak',
	decline: 'Ditolak',
	export: 'Export',
	edit_user: 'Edit Pengguna',
	id: 'id',
	action: 'Action',
	city: 'Kota',
	document: 'Dokumen',
	edit_provider: 'Edit Penyedia Jasa',
	view_documents: 'Lihat Dokumen',
	country: 'Negara',
	service: 'Layanan',
	expiry_date: 'Tanggal Kadaluwarsa',
	status: 'Status',
	date: 'Tanggal',
	payment_detail: 'Rincian Pembayaran',
	service_payment: 'Pembayaran Layanan',
	invoice: 'Invoice',
	detail: 'Rincian',
	single: 'Single',
	group: 'Group',
	pay_collect: 'Pembayaran yang Sudah Diterima',
	settlement_by: 'Diselesaikan Oleh',
	type: 'Jenis',
	added_amount: 'Jumlah Tambahan',
	total_amount: 'Jumlah Keseluruhan',
	settings: 'Pengaturan',
	installation_settings: 'Pengaturan Instalasi',
	app_settings: 'Pengaturan Aplikasi',
	color_settings: 'Pengaturan Warna',
	language: 'Bahasa',
	add_language: 'Tambah Bahasa',
	add_new_language: 'Tambah Bahasa Baru',
	enter_language: 'Masukkan Bahasa',
	enter_code: 'Masukkan Kode',
	mandatory: 'Penugasan',
	email_unique_title: 'Judul Email yang Unik',
	email_title: 'Judul Email',
	email_content: 'Tulis Konten Email',
	is_send: 'Dikirim',
	nexmo_name: 'nexmo_name',
	nexmo_api_key: 'nexmo_api_key',
	nexmo_secret_key: 'nexmo_secret_key',
	sms_unique_title: 'Judul SMS yang Unik',
	sms_content: 'Tulis Konten SMS',
	tax_included: 'Termasuk Pajak',
	tax_excluded: 'Belum Termasuk Pajak',
	edit_employee: 'Ubah Pekerja',
	availability: 'Ketersediaan',
	provider_availability: 'Ketersediaan Penyedia Jasa',
	not_available_for_the_whole_day: 'Penuh Sepanjang Hari',
	working_on_split_shifts: 'Bekerja pada Pembagian Shift',
	by_hour: 'Per Jam',
	no_availability_rule_on_this_date: 'Layanan Tidak Tersedia untuk Tanggal ini',
	accept: 'Setuju',
	accept_and_edit: 'Setuju dan Edit',
	reject: 'Ditolak',
	wait_for_user_response: 'Menunggu Balasan Pengguna',
	details: 'Rincian',
	sign_out: 'Keluar',
	sign_in_to_your_account: 'Masuk ke Akun Anda',
	forgot_password: 'Lupa Password',
	create_a_new_account: 'Buat Akun Baru',
	sign_in: 'Masuk',
	otp_sent: 'OTP Terkirim',
	back_to_login: 'Kembali ke Halaman Login',
	lets_get_started: 'Mari Kita Mulai',
	sign_up_to_use_our_service: 'Daftar untuk menikmati layanan kami',
	sign_in_to_existing_account: 'Masuk Akun Anda',
	sign_up: 'Daftar',
	terms_and_condition: 'Syarat dan Ketentuan yang Berlaku',
	i_accept: 'Saya Setuju',
	material_cost: 'Biaya untuk Bahan'
}

export var validation_message = {
	this_field_is_required: 'Wajib Diisi',
	city_already_exist: 'Kota sudah pernah masuk',
	password_short:'Password minimum 6 karakter',
	phone_number_short:'Nomor handphone Tidak Terdaftar'
}

export var button = {
	approve: 'Diterima',
	change_time: 'Ubah Waktu',
	add_city: 'Tambah Kota',
	add_country: 'Tambah Negara',
	submit: 'Submit',
	update: 'Perbarui',
	add_service: 'Tambah Layanan',
	add_sub_service: 'Tambah Sub-Layanan',
	add: 'Tambah',
	add_slot: 'Tambah Slot',
	cancel: 'Batal',
	detail: 'Rincian',
	clear: 'Hapus'
}

export var heading_title = {
	add_city_data: 'Tambah Data Kota',
	service_list: 'Daftar Layanan',
	sub_service_list: 'Daftar Sub-Layanan',
	service_detail: 'Rincian Layanan',
	sub_service_detail: 'Rincian Sub-Layanan',
	admin_setting: 'Pengaturan Admin',
	user_setting: 'Pengaturan Pengguna',
	provider_setting: 'Pengaturan Penyedia Jasa',
	s3_bucket_setting: 's3_bucket_setting',
	google_key: 'Google Key',
	stripe_key: 'stripe_key',
	gcm_key: 'gcm_key',
	user_app_force_update: 'Pengguna Mohon Perbarui Aplikasi',
	provider_app_force_update: 'Penyedia Jasa Mohon Perbarui Aplikasi',
	ios_push_setting: 'ios_push_setting',
	sub_services: 'Sub-Layanan',
	status_detail: 'Rincian Status',
	invoice_detail: 'Rincian Invoice',
	user_app: 'Aplikasi Pengguna',
	provider_app: 'Aplikasi Penyedia Jasa',
	website: 'Website'
}