import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './shared/components/layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { AuthGuard } from './shared/services/auth/auth.guard';

export const rootRouterConfig: Routes = [
  
  {
    path: '', 
    component: AuthLayoutComponent,
    children: [
      { 
        path: 'company', 
        loadChildren: './views/admin/sessions/sessions.module#SessionsModule',
        data: { title: 'Company'} 
      }
    ]
  },
  {
    path: '', 
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { 
        path: 'company', 
        loadChildren: './views/admin/admin.module#AdminModule', 
        data: { title: 'Company', breadcrumb: 'Admin'}
      }
    ]
  },
  { 
    path: '**', 
    redirectTo: 'company/requests'
  }
];

