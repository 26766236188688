import { Injectable } from "@angular/core";
import { Helper } from "app/helper";
import { BehaviorSubject } from "rxjs";

interface IMenuItem {
  type: string; // Possible values: link/dropDown/icon/separator/extLink
  name?: string; // Used as display text for item and title for separator type
  state?: string; // Router state
  icon?: string; // Material icon name
  tooltip?: string; // Tooltip text
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown items
  badges?: IBadge[];
}
interface IChildItem {
  type?: string;
  name: string; // Display text
  state?: string; // Router state
  icon?: string;
  sub?: IChildItem[];
}

interface IBadge {
  color: string; // primary/accent/warn/hex color codes(#fff000)
  value: string; // Display text
}

@Injectable()
export class NavigationService {
  constructor(public helper: Helper) {}
  iconMenu: IMenuItem[] = [
    
    {
      name: this.helper.trans.instant("profile"),
      type: "link",
      tooltip: "Profile",
      icon: "admin_icons/user.png",
      state: "company/profile"
    }
    ,
    {
      name: this.helper.trans.instant("employees"),
      type: "link",
      tooltip: "Employees",
      icon: "admin_icons/providers.png",
      state: "company/providers"
    }
    ,
    {
      name: this.helper.trans.instant("ongoing_requests"),
      type: "link",
      tooltip: "Requests",
      icon: "admin_icons/forward_request.png",
      state: "company/requests"
    },
    {
      name: this.helper.trans.instant("history"),
      type: "link",
      tooltip: "History",
      icon: "admin_icons/history.png",
      state: "company/history"
    },
    {
      name: this.helper.trans.instant("earning"),
      type: "link",
      tooltip: "Earning",
      icon: "admin_icons/earnings.png",
      state: "company/earning"
    },
    {
      name: this.helper.trans.instant("service_price"),
      type: "link",
      tooltip: "Service Price",
      icon: "admin_icons/service_price.png",
      state: "company/service_price"
    },
    {
      name: this.helper.trans.instant("documents"),
      type: "link",
      tooltip: "Documents",
      icon: "admin_icons/documents.png",
      state: "company/documents"
    },
    {
      name: this.helper.trans.instant("wallet_history"),
      type: "link",
      tooltip: "Wallet History",
      icon: "admin_icons/wallet.png",
      state: "company/wallet_history"
    }
  ];

  separatorMenu: IMenuItem[] = [
    {
      type: "separator",
      name: "Custom components"
    },
    {
      name: "DASHBOARD",
      type: "link",
      tooltip: "Dashboard",
      icon: "dashboard",
      state: "dashboard"
    }
  ];

  plainMenu: IMenuItem[] = [
    {
      name: "DASHBOARD",
      type: "link",
      tooltip: "Dashboard",
      icon: "dashboard",
      state: "dashboard"
    }
  ];

  // Icon menu TITLE at the very top of navigation.
  // This title will appear if any icon type item is present in menu.
  iconTypeMenuTitle: string = "";
  // sets iconMenu as default;
  menuItems = new BehaviorSubject<IMenuItem[]>(this.iconMenu);
  // navigation component has subscribed to this Observable
  menuItems$ = this.menuItems.asObservable();

  // Customizer component uses this method to change menu.
  // You can remove this method and customizer component.
  // Or you can customize this method to supply different menu for
  // different user type.
  publishNavigationChange(menuType: string) {
    switch (menuType) {
      case "separator-menu":
        this.menuItems.next(this.separatorMenu);
        break;
      case "icon-menu":
        this.menuItems.next(this.iconMenu);
        break;
      default:
        this.menuItems.next(this.plainMenu);
    }
  }
}
